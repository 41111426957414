module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://kotlet1.local/graphql","type":{"Post":{},"Page":{"limit":null},"Comment":{"exclude":true},"Tag":{},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"schema":{"timeout":240000,"requestConcurrency":2,"previewRequestConcurrency":1,"perPage":50,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"develop":{"nodeUpdateInterval":5000,"hardCacheMediaFiles":false,"hardCacheData":false},"production":{"allow404Images":true,"hardCacheMediaFiles":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"kotlet.tv","short_name":"kotlet.tv","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/kotlet-icon.png","theme_color_in_head":false,"icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a96f736742d6d116e533a04377c30c59"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleAnalytics":{"trackingId":"G-1DKZEJYTN5","autoStart":false,"anonymize":true,"controlCookieName":"gdpr_non-essential-enabled","cookieFlags":"secure;samesite=none"},"googleAds":{"trackingId":"","anonymize":true,"controlCookieName":"gdpr-marketing-enabled","cookieFlags":"secure;samesite=none"},"facebookPixel":{"pixelId":"","controlCookieName":"gdpr-marketing-enabled"},"hotjar":{"trackingId":"","snippetVersion":"6","controlCookieName":"gdpr-analytics-enabled"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
