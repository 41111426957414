// custom typefaces
// import "typeface-montserrat"
// import "@fontsource/hammersmith-one/400.css"

import "@fontsource/cabin-sketch/700.css"

import "@fontsource/merriweather/latin-300.css"
import "@fontsource/merriweather/latin-300-italic.css"
import "@fontsource/merriweather/latin-ext-300.css"
import "@fontsource/merriweather/latin-ext-300-italic.css"
import "@fontsource/merriweather/latin-400.css"
import "@fontsource/merriweather/latin-400-italic.css"
import "@fontsource/merriweather/latin-ext-400.css"
import "@fontsource/merriweather/latin-ext-400-italic.css"
import "@fontsource/merriweather/latin-700.css"
import "@fontsource/merriweather/latin-700-italic.css"
import "@fontsource/merriweather/latin-ext-700.css"
import "@fontsource/merriweather/latin-ext-700-italic.css"
import "@fontsource/merriweather/latin-900.css"
import "@fontsource/merriweather/latin-900-italic.css"
import "@fontsource/merriweather/latin-ext-900.css"
import "@fontsource/merriweather/latin-ext-900-italic.css"

import "@fontsource/open-sans/latin-300.css"
import "@fontsource/open-sans/latin-300-italic.css"
import "@fontsource/open-sans/latin-ext-300.css"
import "@fontsource/open-sans/latin-ext-300-italic.css"
import "@fontsource/open-sans/latin-400.css"
import "@fontsource/open-sans/latin-400-italic.css"
import "@fontsource/open-sans/latin-ext-400.css"
import "@fontsource/open-sans/latin-ext-400-italic.css"
import "@fontsource/open-sans/latin-600.css"
import "@fontsource/open-sans/latin-600-italic.css"
import "@fontsource/open-sans/latin-ext-600.css"
import "@fontsource/open-sans/latin-ext-600-italic.css"
import "@fontsource/open-sans/latin-700.css"
import "@fontsource/open-sans/latin-700-italic.css"
import "@fontsource/open-sans/latin-ext-700.css"
import "@fontsource/open-sans/latin-ext-700-italic.css"
import "@fontsource/open-sans/latin-800.css"
import "@fontsource/open-sans/latin-800-italic.css"
import "@fontsource/open-sans/latin-ext-800.css"
import "@fontsource/open-sans/latin-ext-800-italic.css"

import "@fontsource/open-sans-condensed/latin-300.css"
// import "@fontsource/open-sans-condensed/latin-300-italic.css"
import "@fontsource/open-sans-condensed/latin-ext-300.css"
// import "@fontsource/open-sans-condensed/latin-ext-300-italic.css"
import "@fontsource/open-sans-condensed/latin-700.css"
import "@fontsource/open-sans-condensed/latin-ext-700.css"

// normalize CSS across browsers
import "./src/normalize.css"

// custom CSS styles
import "./src/style.css"

// sass
import "./src/style.scss"
